<template>
  <v-card class="course-card">
    <v-toolbar light dense :color="color" :elevation="0">
      <v-toolbar-title class="white--text">
        <span :class="['text-ellips']">{{ course.name }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-icon class="mx-2" v-if="course.shared" color="white">mdi-home-group</v-icon>
      <v-icon class="mx-2" v-if="course.canDelete" @click="deleteDialog = true" color="white">mdi-delete</v-icon>
      <v-icon class="mx-2" v-if="course.canUpdate" @click="dialog = true" color="white">mdi-pencil</v-icon>
      <v-icon @click="$router.push({ name: 'Course', params: { id: course.id } })" color="white">mdi-arrow-right</v-icon>
    </v-toolbar>
    <v-list>
      <v-list-item v-if="course.organizer">
        <v-list-item-subtitle class="col-auto pa-0 pr-2">
          {{ $t("headers.organizer") }}
        </v-list-item-subtitle>
        <v-list-item-title class="text-right">
          {{ course.organizer }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="course.manager">
        <v-list-item-subtitle class="col-auto pa-0 pr-2">
          {{ $t("headers.manager") }}
        </v-list-item-subtitle>
        <v-list-item-title class="text-right">
          {{ course.manager ? course.manager.complete_name : "Nessuno" }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="course.shared">
        <v-list-item-subtitle class="col-auto pa-0 pr-2">
          {{ $t("education.shared_from") }}
        </v-list-item-subtitle>
        <v-list-item-title class="text-right">
          {{ course.area.name }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="course.rating">
        <v-list-item-subtitle>
          {{ $t("course.rating") }}
        </v-list-item-subtitle>
        <v-col cols="auto pa-0 pl-2">
          <v-rating small readonly half-increments :value="course.rating" />
        </v-col>
      </v-list-item>

      <v-list-item v-if="course.eventsRating">
        <v-list-item-subtitle>
          {{ $t("course.eventsRating") }}
        </v-list-item-subtitle>
        <v-col cols="auto pa-0 pl-2">
          <v-rating small readonly half-increments :value="course.eventsRating" />
        </v-col>
      </v-list-item>

      <v-list-item v-if="course.poster" class="justify-center">
        <v-btn dark :color="color" small @click="preview = true"> {{ $t("course.poster") }} </v-btn>
        <file-preview :file_name="filename" v-model="preview" :src="course.poster.link" :title="$t('course.poster')"></file-preview>
      </v-list-item>
    </v-list>

    <!-- <create-edit-dialog :editedItem="extractIds(course)" model="course" v-if="dialog" :dialog="dialog" @save="updateCourse" @close="dialog = false" /> -->
    <v-dialog v-if="course.canUpdate" v-model="dialog" persistent max-width="800px">
      <course-form @cancel="dialog = false" @save="dialog = false" :course_id="course.id" />
    </v-dialog>
    <delete-dialog v-if="course.canDelete" v-model="deleteDialog" @confirm="deleteCourse" />
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import DeleteDialog from "../DeleteDialog.vue";
import { updateMutationBuilder } from "../../graphql/mutations";
import { addToCacheFragmentArray, extractIds, removeFromCacheFragmentArray } from "../../apollo/helpers";
import CourseForm from "../forms/CourseForm.vue";
import FilePreview from "../base/FilePreview.vue";

export const COURSE_CARD_INFO_FRAGMENT = gql`
  fragment courseCardInfo on Course {
    id
    name
    organizer
    shared
    canUpdate
    canDelete
    rating
    eventsRating
    manager {
      id
      complete_name
    }
    tag {
      id
      color
    }
    area {
      id
      name
    }
    year {
      id
    }
    poster: file(name: "poster") {
      id
      name
      link
    }
  }
`;

const TAG_COURSES_FRAGMENT = gql`
  fragment tagCourses on Tag {
    id
    courses {
      ...courseCardInfo
    }
  }
  ${COURSE_CARD_INFO_FRAGMENT}
`;

const AREA_COURSES_FRAGMENT = gql`
  fragment areaUntaggedCourses on Area {
    id
    untaggedCourses {
      ...courseCardInfo
    }
  }
  ${COURSE_CARD_INFO_FRAGMENT}
`;

export default {
  components: { DeleteDialog, CourseForm, FilePreview },
  name: "CourseCard",
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      preview: false,
    };
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },

  computed: {
    color() {
      return this.course.tag ? this.course.tag.color : "secondary";
    },

    filename() {
      return `${this.$t("course.poster")} - ${this.course.name}`;
    },
  },

  methods: {
    updateCacheInfo(course) {
      return {
        fragment_info: {
          fragment: course.tag ? TAG_COURSES_FRAGMENT : AREA_COURSES_FRAGMENT,
          fragmentName: course.tag ? "tagCourses" : "areaUntaggedCourses",
          id: course.tag ? `Tag:${course.tag.id}` : `Area:${course.area.id}`,
        },
        array_path: course.tag ? "courses" : "untaggedCourses",
      };
    },

    updateCourse({ id, ...input }) {
      this.dialog = false;
      this.$apollo.mutate({
        mutation: updateMutationBuilder({ model: "Course", fragment: COURSE_CARD_INFO_FRAGMENT, fragment_name: "courseCardInfo" }),
        variables: {
          id,
          input,
        },
        update: (store, { data: { updateCourse } }) => {
          if (updateCourse.tag?.id != this.course.tag?.id) {
            addToCacheFragmentArray(store, {
              ...this.updateCacheInfo(updateCourse),
              newElement: updateCourse,
              unshift: true,
            });
            removeFromCacheFragmentArray(store, {
              ...this.updateCacheInfo(this.course),
              id: updateCourse.id,
            });
          }
        },
      });
    },

    deleteCourse() {
      this.$emit("delete", { id: this.course.id });
    },

    extractIds,
  },
};
</script>

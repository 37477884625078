<template>
  <v-container class="h-100 d-flex flex-column">
    <v-col cols="auto" class="pa-0">
      <v-card
        class="overflow-hidden mb-2"
        rounded="lg"
        :class="{
          'd-none': $apollo.queries.viewableEducationAreas.loading || viewableEducationAreas.length === 1,
        }"
      >
        <v-card-title>
          <v-select
            item-value="id"
            item-text="name"
            v-model="selectedArea"
            :items="viewableEducationAreas"
            :loading="loading"
            :label="$t('area.name')"
            return-object
          />
        </v-card-title>
      </v-card>
      <v-card class="overflow-hidden mb-8" rounded="lg">
        <v-card-title>
          <v-select
            item-value="id"
            item-text="name"
            v-model="selectedYear"
            :items="selectedArea?.client.years ?? []"
            :loading="loading"
            :label="$t('year.name')"
            return-object
          />
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="auto" class="pa-0">
      <v-row no-gutters align="center" v-if="tags.length > 0">
        <v-spacer />
        <v-btn
          :disabled="tab <= 0"
          icon
          class="d-none d-sm-block mr-1"
          @click="$router.push(previousRoute)"
          :color="tagsWithEmpty[tab].color"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-chip
          style="min-width: 320px"
          @click="overlay = true"
          class="text-button d-flex justify-center"
          :color="tagsWithEmpty[tab].color"
          link
          label
          dark
          >{{ tagsWithEmpty[tab].name }}</v-chip
        >
        <v-btn
          :disabled="tab >= tags.length"
          icon
          class="d-none d-sm-block ml-1"
          @click="$router.push(nextRoute)"
          :color="tagsWithEmpty[tab].color"
        >
          <v-icon style="height: 100%">mdi-chevron-right</v-icon>
        </v-btn>
        <v-spacer />
      </v-row>
    </v-col>
    <v-col class="flex-grow-1 pa-0">
      <v-window class="h-100" v-if="tags.length > 0" v-model="window">
        <v-window-item
          :value="i"
          class="h-100"
          v-for="(tag, i) in tags"
          :key="selectedYear.id + '_' + tag.id"
        >
          <courses-grid :canCreate="canCreate" :area="selectedArea" :year="selectedYear" :tag="tag" />
        </v-window-item>
        <v-window-item :value="tags.length" class="h-100" :key="selectedYear.id + '-1'">
          <courses-grid :canCreate="canCreate" :area="selectedArea" :year="selectedYear" />
        </v-window-item>
      </v-window>
    </v-col>

    <v-overlay v-if="tags.length > 0" :class="{ blurred: overlay }" :opacity="0.9" v-model="overlay">
      <div v-click-outside="() => (overlay = false)">
        <v-chip
          @click="selectTag(tag)"
          v-for="tag in tagsWithEmpty"
          :key="tag.id"
          class="my-1 d-flex"
          :color="tag.color"
          link
          label
          dark
          large
        >
          <span class="text-ellips text-button">{{ tag.name }}</span>
        </v-chip>
      </div>
    </v-overlay>
  </v-container>
</template>

<style scoped>
.tab-content {
  min-height: calc(75%);
}
.new-course {
  min-height: 150px;
}
</style>

<script>
import { throttle } from "lodash";
import CoursesGrid from "../components/education/CoursesGrid.vue";
import Vue from "vue";

export default {
  name: "Education",
  components: { CoursesGrid },

  apollo: {
    viewableEducationAreas: {
      query: require("../graphql/queries/ViewableEducationAreas.gql"),
      update(data) {
        if (data.viewableEducationAreas) this.autoSetQueryParameters(data.viewableEducationAreas);
        return data.viewableEducationAreas;
      },
    },
  },

  data() {
    return {
      overlay: false,
      disableWindowChange: false,
      viewableEducationAreas: [],
    };
  },

  watch: {
    $route() {
      if (this.viewableEducationAreas) this.autoSetQueryParameters(this.viewableEducationAreas);
    },
  },

  computed: {
    autoSetQueryParameters() {
      return throttle((viewableEducationAreas) => {
        if (viewableEducationAreas.length > 0 && !this.$route.query.area_id)
          this.selectedArea = viewableEducationAreas[0];
      }, 100);
    },
    previousRoute() {
      if (this.tab <= 0) return null;

      return {
        name: "Education",
        query: {
          area_id: this.selectedArea.id,
          year_id: this.selectedYear.id,
          tag_id: this.tagsWithEmpty[this.tab - 1].id,
        },
      };
    },

    nextRoute() {
      if (this.tab >= this.tags.length) return null;

      return {
        name: "Education",
        query: {
          area_id: this.selectedArea.id,
          year_id: this.selectedYear.id,
          tag_id: this.tagsWithEmpty[this.tab + 1].id,
        },
      };
    },

    selectedArea: {
      get() {
        return this.viewableEducationAreas?.find((area) => area.id === this.$route.query.area_id);
      },
      set(area) {
        this.$router.replace({
          name: "Education",
          query: this.getAreaInitialParameters(area),
        });
      },
    },

    selectedYear: {
      get() {
        return this.selectedArea?.client.years.find((year) => year.id === this.$route.query.year_id);
      },
      set(year) {
        this.disableWindowChange = true;
        this.$router.replace({
          name: "Education",
          query: this.getYearInitialParameters(year),
        });
        Vue.nextTick(() => {
          this.disableWindowChange = false;
        });
      },
    },

    selectedTag: {
      get() {
        return this.tagsWithEmpty.find((tag) => tag.id === this.$route.query.tag_id);
      },
      set(tag) {
        this.$router.replace({
          name: "Education",
          query: this.getTagInitialParameters(tag),
        });
      },
    },

    window: {
      get() {
        return this.tagsWithEmpty.findIndex((tag) => tag.id === this.$route.query.tag_id) ?? 0;
      },
      set(index) {
        this.$router.replace({
          name: "Education",
          query: {
            area_id: this.selectedArea.id,
            year_id: this.selectedYear.id,
            tag_id: this.tagsWithEmpty[index].id,
          },
        });
      },
    },

    loading() {
      return this.$apollo.queries.viewableEducationAreas.loading;
    },

    tags() {
      return this.selectedYear?.tags ?? [];
    },

    tagsWithEmpty() {
      return [...this.tags, { id: "-1", name: this.$t("education.no_tag"), color: "secondary" }];
    },

    tab() {
      if (!this.$route.query.tag_id) return 0;
      return this.tagsWithEmpty.findIndex((tag) => tag.id === this.$route.query.tag_id);
    },

    canCreate() {
      return this.selectedArea.canCreateCourses;
    },
  },

  methods: {
    selectTag(tag) {
      this.overlay = false;
      this.selectedTag = tag;
    },

    getAreaInitialParameters(area) {
      const parameters = { area_id: area.id };

      if (area.client.years.length > 0) {
        parameters.year_id = area.client.years[0].id;
      }

      return parameters;
    },

    getYearInitialParameters(year) {
      const parameters = { area_id: this.selectedArea.id, year_id: year.id };
      return parameters;
    },

    getTagInitialParameters(tag) {
      const parameters = { area_id: this.selectedArea.id, year_id: this.selectedYear.id, tag_id: tag.id };
      return parameters;
    },
  },
};
</script>

<style scoped>
.blurred {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
</style>

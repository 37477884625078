<template>
  <v-row class="ma-0 py-6">
    <v-col v-if="canCreate" cols="12" sm="6" lg="4" xl="3">
      <v-card class="new-course d-flex justify-center align-center h-100">
        <v-icon @click="dialog = true" large color="primary"> mdi-plus </v-icon>
      </v-card>
    </v-col>
    <v-col v-for="course in courses" :key="course.id" cols="12" sm="6" lg="4" xl="3">
      <course-card :course="course" @delete="deleteCourse" />
    </v-col>

    <v-col v-for="course in sharedCourses" :key="course.id" cols="12" sm="6" lg="4" xl="3">
      <course-card :course="course" @delete="deleteCourse" />
    </v-col>

    <!-- <create-edit-dialog :baseItem="baseItem" :dialog="dialog" model="course" @save="createCourse" @close="dialog = false" /> -->

    <v-dialog v-model="dialog" max-width="800px" persistent>
      <course-form :baseItem="baseItem" @cancel="dialog = false" @save="dialog = false" @created="courseCreated" />
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import CourseCard, { COURSE_CARD_INFO_FRAGMENT } from "./CourseCard.vue";
import { deleteModelMutation } from "../../graphql/mutations";
import { removeFromCacheArray, addToCacheArray } from "../../apollo/helpers";
import CourseForm from "../forms/CourseForm.vue";

const AREA_COURSES_QUERY = gql`
  query Courses($tag_id: ID, $year_id: ID, $area_id: ID!) {
    area(id: $area_id) {
      id
      courses(year_id: $year_id, tag_id: $tag_id) {
        ...courseCardInfo
      }
      sharedCourses(year_id: $year_id, tag_id: $tag_id) {
        ...courseCardInfo
      }
    }
  }
  ${COURSE_CARD_INFO_FRAGMENT}
`;

export default {
  components: { CourseCard, CourseForm },
  props: {
    tag: {
      type: Object,
      default: () => ({ id: null }),
    },
    year: {
      type: Object,
      required: true,
    },
    area: {
      type: Object,
      required: true,
    },
    canCreate: Boolean,
  },
  name: "CoursesGrid",

  data() {
    return {
      areaQuery: null,
      dialog: false,
    };
  },

  computed: {
    baseItem() {
      return { tag: this.tag, area: this.area, year: this.year };
    },
    loading() {
      return this.$apollo.queries.courses.loading;
    },

    courses() {
      return this.areaQuery?.courses ?? [];
    },
    sharedCourses() {
      return this.areaQuery?.sharedCourses ?? [];
    },
  },

  apollo: {
    areaQuery: {
      query: AREA_COURSES_QUERY,
      variables() {
        return {
          tag_id: this.tag.id,
          year_id: this.year.id,
          area_id: this.area.id,
        };
      },
      update(data) {
        return data.area;
      },
    },
  },

  methods: {
    courseCreated(course) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: AREA_COURSES_QUERY,
          variables: {
            tag_id: this.tag.id,
            year_id: this.year.id,
            area_id: this.area.id,
          },
        },
        queryField: "area.courses",
        newElement: course,
        unshift: true,
      });
      this.dialog = false;
    },

    deleteCourse({ id }) {
      this.$apollo.mutate({
        mutation: deleteModelMutation("Course"),
        variables: { id },
        update: (store) => {
          removeFromCacheArray(store, {
            query: {
              query: AREA_COURSES_QUERY,
              variables: {
                tag_id: this.tag.id,
                year_id: this.year.id,
                area_id: this.area.id,
              },
            },
            queryField: "area.courses",
            id,
          });
        },
      });
    },
  },
};
</script>

<style>
.new-course {
  min-height: 150px;
}
</style>
